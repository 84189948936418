<template>
    <div>
        <b-card title="上架號碼說明">
            <b-card-text>系統會預先載入一個尚未使用的上架號碼，如果你不喜歡，可以自己打或是從下面選一些。</b-card-text>
            <b-card-text>
                <ul>
                    <li  v-bind:key="index" v-for="(item, index) in emptyIds">
                        <b-button variant="link" @click="throwNumber(item)">{{item}}</b-button>
                    </li>
                </ul>
            </b-card-text>
        </b-card>
    </div>
</template>

<script>
    import axios from "axios";

    export default {
        name: "store",
        props: ['search'],
        data: function(){
            return {
                emptyIds: [],
                cron: null
            }
        },
        methods:{
            loadEmptyIds(){
                axios.get('/api/package/suggest?type=empty_pids')
                .then((response)=>{
                    this.emptyIds = response.data;
                })
            },
            throwNumber(number){
                this.$emit('numberPicked', number);
            }
        },
        mounted: function () {
            this.loadEmptyIds();
            this.$nextTick(function () {
                this.corn = window.setInterval(() => {
                    this.loadEmptyIds();
                },10000);
            })
        },
        beforeDestroy: function () {
            if(this.corn !== null){
                window.clearInterval(this.corn);
            }
        }
    }
</script>

<style scoped>

</style>
<template>
    <div>
        <b-card title="房間快找說明">
            <b-card-text>輸入格式為五位數數字，分別代表宿舍、房號，第一位為宿舍別，第二位為棟，第三位為房間號碼；範例如下：<br>一宿A221：11221、二宿B302：22302</b-card-text>
            <b-card-text>
                <ul v-if="resp.length >= 1">
                    <li  v-bind:key="index" v-for="(item, index) in resp" >
                        <b-button variant="link" @click="throwValue(item)">{{item.show}}</b-button>
                    </li>
                </ul>
            </b-card-text>
        </b-card>
    </div>
</template>

<script>
    import axios from "axios";

    export default {
        name: "quickRoomSearch",
        props:{
            room: String
        },
        data: function () {
            return {
                resp: []
            }
        },
        watch: {
            room: function (nVal) {
                if(nVal.length === 5){
                    this.matchRoom();
                }
            }
        },
        methods:{
            matchRoom(){
                axios.get('/api/package/suggest', {
                    params: {
                        type: "room",
                        keyword: this.room
                    }
                }).then((response)=>{
                    this.resp = response.data;
                })
            },
            throwValue(item){
                this.$emit('userSelected', item);
            }
        }
    }
</script>

<style scoped>

</style>
<template>
    <b-card title="找人">
        <b-card-text>請輸入人名，如果名字中間有字不知道怎麼打，可以打%取代；例如『王%名』可以找到像是：王小名、王中名、王大名、王名之類的名字。</b-card-text>
        <b-card-text>
            <ul>
                <li  v-bind:key="index" v-for="(item, index) in resp">
                    <b-button variant="link" @click="throwValue(item)">{{item.show}}</b-button>
                </li>
            </ul>
        </b-card-text>
    </b-card>
</template>

<script>
    import axios from "axios";

    export default {
        name: "name",
        props:{
            name: String
        },
        data: function () {
            return {
                resp: []
            }
        },
        watch: {
            name: function (nVal, oVal) {
                if(nVal !== oVal){
                    this.matchName();
                }
            }
        },
        methods:{
            matchName(){
                axios.get('/api/package/suggest', {
                    params: {
                        type: "name",
                        keyword: this.name
                    }
                }).then((response)=>{
                    this.resp = response.data;
                })
            },
            throwValue(item){
                this.$emit('userSelected', item);
            }
        }
    }
</script>

<style scoped>

</style>
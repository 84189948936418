<template>
    <b-card title="一些備註">
        <b-card-text>這是我想的到的備註，點一下就可以填入；當然想要自己打也是完全大丈夫。</b-card-text>
        <b-card-text>
            <ul>
                <li  v-bind:key="index" v-for="(item, index) in companies">
                    <b-button variant="link" @click="throwValue(item)">{{item}}</b-button>
                </li>
            </ul>
        </b-card-text>
    </b-card>
</template>

<script>
    export default {
        name: "remark",
        data: function () {
            return {
                companies: [
                    "請記得填寫房號",
                    "請記得填寫手機號碼",
                    "剩下的我想不到了…"
                ]
            }
        },
        methods: {
            throwValue(value){
                this.$emit('remarkSelected', value);
            }
        }
    }
</script>

<style scoped>

</style>
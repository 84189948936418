<template>
    <b-card title="櫃位選取">
        <b-card-text>這是好用的一些櫃子，點一下就可以填入；當然想要自己打也是完全大丈夫。</b-card-text>
        <b-card-text>
            <ul>
                <li  v-bind:key="index" v-for="(item, index) in companies">
                    <b-button variant="link" @click="throwValue(item)">{{item}}</b-button>
                </li>
            </ul>
        </b-card-text>
    </b-card>
</template>

<script>
    export default {
        name: "shelf",
        data: function () {
            return {
                companies: [
                    "A",
                    "B",
                    "C",
                    "D",
                    "E",
                    "F",
                    "1",
                    "2",
                    "3",
                    "4",
                    "5",
                    "6"
                ]
            }
        },
        methods: {
            throwValue(value){
                this.$emit('shelfSelected', value);
            }
        }
    }
</script>

<style scoped>

</style>
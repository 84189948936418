<template>
    <div class="row justify-content-center">
        <b-modal
                id="move-package-modal"
                @hidden="resetModalValue"
                title="移動包裹位置"
                cancel-title="取消"
                ok-title="確認"
                @ok="movePackage"
        >
            <b-form-group
                    label-cols-sm="3"
                    label="放置地點："
                    label-align-sm="right"
                    label-for="store"
            >
                <b-form-input v-model="packageList.modalValue.p1" id="place-m"/>
            </b-form-group>
                <b-form-group
                        label-cols-sm="3"
                        label="放置架子："
                        label-align-sm="right"
                        label-for="shelf-m"
                >
                    <b-form-input v-model="packageList.modalValue.p2" id="shelf-m"/>
                </b-form-group>
        </b-modal>
        <div class="col-lg-4">
            <b-card title="郵件資料">
                <b-card-body>
                    <b-form-group
                            label-for="multiMode-button"
                    >
                        <b-form-checkbox v-model="multiMode" id="multiMode-button" switch>
                            目前狀態：<b>({{ multiMode? '多筆資料一次匯入': '單一包裹輸入' }})</b>
                        </b-form-checkbox>
                    </b-form-group>
                    <b-form-group
                            v-show="!multiMode"
                            label-cols-sm="3"
                            label="物流單號："
                            label-align-sm="right"
                            label-for="tracer"
                    >
                        <b-form-input @focus="middleName = 'tracer'" v-model="newPackageData.tracer" @keypress.enter="$refs.tracer.matchTracerId()" id="tracer"/>
                    </b-form-group>
                    <b-form-group
                            label-cols-sm="3"
                            label="物流業者："
                            label-align-sm="right"
                            label-for="deliver"
                    >
                        <b-form-input @focus="middleName = 'logisticsCompany'" v-model="newPackageData.deliver" id="deliver"/>
                    </b-form-group>
                    <b-form-group
                            label-cols-sm="3"
                            label="放置地點："
                            label-align-sm="right"
                            label-for="store"
                    >
                        <b-form-input @focus="middleName = 'place'" v-model="newPackageData.place" id="place"/>
                    </b-form-group>
                </b-card-body>
                <b-card-body v-if="!multiMode">
                    <b-form-group
                            v-if="!multiMode"
                            label-cols-sm="3"
                            label="放置架子："
                            label-align-sm="right"
                            label-for="shelf"
                    >
                        <b-form-input @focus="middleName = 'shelf'" v-model="newPackageData.place_1" id="shelf"/>
                    </b-form-group>
                    <b-form-group
                            label-cols-sm="3"
                            label="上架號："
                            label-align-sm="right"
                            label-for="store"
                    >
                        <b-form-input @focus="middleName = 'store_mod'" type="number" v-model="newPackageData.packageId" id="store"/>
                    </b-form-group>
                    <b-form-group
                            label-cols-sm="3"
                            label="房間快找："
                            label-align-sm="right"
                            label-for="tracer"
                    >
                        <b-form-input :autocomplete="false" v-bind:disabled="user.find" v-model="roomQuickFind" @focus="middleName = 'quickRoomSearch'" id="tracer"/>
                    </b-form-group>
                    <b-form-group
                            label-cols-sm="3"
                            label="姓名："
                            label-align-sm="right"
                            label-for="owner"
                    >
                        <b-form-input :autocomplete="false" v-model="user.name" v-bind:disabled="user.find" @focus="middleName = 'NameSearch'" id="owner"/>
                    </b-form-group>
                    <b-form-group
                            label-cols-sm="3"
                            label="電話："
                            label-align-sm="right"
                            label-for="phone"
                    >
                        <b-form-input :autocomplete="false" v-model="user.phone" @focus="middleName = 'PhoneSearch'" v-bind:disabled="user.find" id="phone"/>
                    </b-form-group>
                </b-card-body>
                <b-card-body v-if="multiMode">
                    <h4>單號（一行一個）：</h4>
                    <b-form-group
                            label-for="multiTracer"
                    >
                        <b-form-textarea v-model="multiArea" id="multiTracer"/>
                    </b-form-group>
                    <p>已經輸入{{multiAreaRowLen}}筆單號</p>
                </b-card-body>
                <b-card-body>
                    <b-form-group
                            label-cols-sm="3"
                            label="非公開備註："
                            label-align-sm="right"
                            label-for="remark_private"
                    >
                        <b-form-textarea v-model="privateRemark" @focus="remarkSelect('Private')" trim id="remark_private"/>
                    </b-form-group>
                    <b-form-group
                            label-cols-sm="3"
                            label="公開備註："
                            label-align-sm="right"
                            label-for="remark_public"
                    >
                        <b-form-textarea v-model="publicRemark" @focus="remarkSelect('Public')" id="remark_public"/>
                    </b-form-group>
                </b-card-body>
                <b-card-footer>
                    <div>
                        <b-button variant="danger" class="btnLeft halfBtn" @click="clearData">清除</b-button>
                        <b-button variant="primary" class="halfBtn" @click="submit" v-bind:disabled="!(multiMode || (user.find && fillPackage))">送出</b-button>
                    </div>
                </b-card-footer>
            </b-card>
        </div>
        <div class="col-sm-2">
            <store_mod v-on:numberPicked="setPid" v-if="middleName === 'store_mod'"/>
            <logistics-company v-on:comSelected="setDeliver" v-if="middleName === 'logisticsCompany'"/>
            <place v-on:comSelected="setPlace" v-if="middleName === 'place'"/>
            <tracer ref="tracer" v-on:userSelected="setUser" :tracer-id="newPackageData.tracer" v-if="middleName === 'tracer'"/>
            <quickRoomSearch v-on:userSelected="setUser" :room="roomQuickFind" v-if="middleName === 'quickRoomSearch'"/>
            <name-find v-on:userSelected="setUser" :name="user.name" v-if="middleName === 'NameSearch'"/>
            <phone-find v-on:userSelected="setUser" :phone="user.phone" v-if="middleName === 'PhoneSearch'"/>
            <remark v-on:remarkSelected="fillOutRemark" v-if="middleName === 'remark'"/>
            <shelf v-on:shelfSelected="setShelf" v-if="middleName === 'shelf'"/>
        </div>
        <div class="col-sm">
            <b-alert :show="packageList.infoFlash !== ''" dismissible @dismissed="packageList.infoFlash = ''">
                {{packageList.infoFlash}}
            </b-alert>
            <b-alert :show="packageList.alertFlask !== ''" variant="danger" dismissible @dismissed="packageList.alertFlask = ''">
                {{packageList.alertFlask}}
            </b-alert>
            <b-row>
                <b-col><b-button variant="outline-primary" block pill @click="notifyAll(0)">領取通知</b-button></b-col>
                <b-col><b-button variant="outline-primary" block pill @click="notifyAll(7)">領取通知(7天）</b-button></b-col>
                <b-col><b-button variant="outline-primary" block pill @click="notifyAll(14)">領取通知(14天）</b-button></b-col>
                <b-col><b-button variant="outline-primary" block pill @click="notifyAll(30)">領取通知(30天）</b-button></b-col>
                <div class="w-100"/>
                <b-col lg="6">
                    <b-form-group
                        label="找人："
                        label-for="searchPerson"
                        description="請直接按Enter搜尋，可輸入證件號碼或學生證"
                    >
                        <b-form-input v-bind:disabled="packageList.user.locked" v-model="packageList.personKeyWord" @keydown.enter="queryPerson" id="searchPerson"/>
                    </b-form-group>
                </b-col>
                <b-col lg="6">
                    <b-form-group
                        label="找包裹："
                        label-for="searchPackage"
                        description="請直接輸入包裹流水號"
                    >
                        <b-form-input v-model="packageList.filter" id="searchPackage"/>
                    </b-form-group>
                </b-col>
                <b-col lg="4">
                    <b-form-group
                            label="每頁"
                            label-for="perPageSelect"
                            label-cols-sm="3"
                    >
                        <b-form-select
                                v-model="packageList.pearPage"
                                id="perPageSelect"
                                size="sm"
                                :options="[5, 10, 30, 50]"
                        ></b-form-select>
                    </b-form-group>
                </b-col>
                <b-col lg="2">
                    <b-form-checkbox
                            v-model="packageList.onlyShowUnpick"
                    >僅顯示待領</b-form-checkbox>
                </b-col>
                <b-col lg="6">
                    <b-pagination
                            v-model="packageList.currentPage"
                            :total-rows="packageList.items.length"
                            :per-page="packageList.pearPage"
                            align="fill"
                            size="sm"
                    />
                </b-col>
                <b-col lg="6"><b-button pill variant="warning" @click="clearPerson" block>清除領取人</b-button></b-col>
                <b-col lg="6"><b-button pill variant="info" @click="syncRecord" block>更新</b-button></b-col>
            </b-row>

            <b-table :items="packageList.items" :fields="packageList.fields" filter="exist" :filter-function="rightTableFilter">
                <template v-slot:cell(c_time)="row">
                    {{new Date(row.value).toLocaleString()}}
                </template>
                <template v-slot:cell(action)="row">
                    <b-button size="sm" variant="success" pill v-if="row.item.status === '待領'" v-bind:disabled="row.item.status !== '待領' || !packageList.user.locked" @click="pickPackage(row.item['record_id'])">領取</b-button>
                    <b-button size="sm" variant="warning" pill v-if="row.item.status === '待領'" @click="managePackage(row.item['record_id'], '退件')">退件</b-button>
                    <b-button size="sm" variant="info" pill @click="row.toggleDetails">{{ row.detailsShowing ? '更少' : '更多'}}</b-button>
                </template>
                <template v-slot:row-details="row">
                    <b-card>
                        <b-card-text>
                            包裹跟蹤編號：{{row.item.tracer}}<br>
                            這件包裹由 {{row.item['recipient']? row.item['recipient']: '宿舍人員'}} 在 {{new Date(row.item['c_time']).toLocaleString()}} 簽收<span v-if="row.item['status'] === '已領取'">（在{{new Date(row.item['close_time']).toLocaleString()}} 由 {{row.item['picker']}} 發給{{row.item['taker']}}）</span><br>
                            收件人：{{row.item['recipient']? row.item['recipient']: '無此資料'}}，<span v-show="row.item['friend']">可以由{{row.item['friend']}}代領</span><br>
                            公開備註：{{row.item['public_remark']}}<br>
                            私密備註：{{row.item['private_remark']}}
                        </b-card-text>
                        <b-card-footer>
                            <b-row>
                                <b-col><b-button size="sm" variant="danger" block pill v-bind:disabled="row.item.status !== '待領'" @click="managePackage(row.item['record_id'], '刪除')">刪除</b-button></b-col>
                                <b-col><b-button size="sm" variant="info" block pill v-bind:disabled="row.item.status !== '待領'" @click="notifyAgain(row.item['record_id'], 'email')">通知領取（Email）</b-button></b-col>
                                <b-col><b-button size="sm" variant="info" block pill v-bind:disabled="row.item.status !== '待領'" @click="notifyAgain(row.item['record_id'], 'line')">通知領取（Line）</b-button></b-col>
                                <b-col><b-button size="sm" variant="primary" block pill v-bind:disabled="row.item.status !== '待領'" @click="showMovePackage(row.item['record_id'])">移動</b-button></b-col>
                            </b-row>
                        </b-card-footer>
                    </b-card>
                </template>
            </b-table>
        </div>
    </div>
</template>

<script>
    import store_mod from '@/components/Mail/store'
    import logisticsCompany from '@/components/Mail/logisticsCompany'
    import place from "@/components/Mail/place";
    import tracer from "@/components/Mail/tracer";
    import quickRoomSearch from "@/components/Mail/quickRoomSearch";
    import name from "@/components/Mail/name";
    import phone from "@/components/Mail/phone";
    import Remark from "@/components/Mail/remark";
    import axios from "axios";
    import shelf from "@/components/Mail/shelf";
    export default {
        name: "Package",
        data: function () {
            return{
                multiMode: false,
                middleName:"store_mod",
                newPackageData:{
                    packageId: '',
                    deliver: '',
                    place: '',
                    place_1:  '',
                    tracer: ''
                },
                publicRemark:'',
                privateRemark: '',
                multiArea: '',
                roomQuickFind: '',
                user:{
                    find: false,
                    name: '',
                    uuid: '',
                    phone: ''
                },
                remarkTarget: '',
                packageList:{
                    items:[],
                    fields:[
                        {
                            key: 'recipient',
                            label: '收件者'
                        },
                        {
                            key: 'pid',
                            label: '包裹號'
                        },
                        {
                            key: 'place',
                            label: '放置地點'
                        },
                        {
                            key: 'place_1',
                            label: '放置位置'
                        },
                        {
                            key: 'status',
                            label: '狀態',
                            sortable: true
                        },
                        {
                            key: 'c_time',
                            label: '建立時間',
                            sortable: true
                        },
                        {
                            key: 'action',
                            label: '操作'
                        }
                    ],
                    pearPage: 10,
                    currentPage: 1,
                    filter: '',
                    user:{
                        // 領取人的資料
                        locked: false,
                        name: '',
                        uuid: ''
                    },
                    onlyShowUnpick: false,
                    personKeyWord: '',
                    infoFlash: '',
                    alertFlask: '',
                    modalValue:{
                        modalPass: '', // 傳送包裹編號到Modal裡面以完成移動位置的操作,
                        p1: '',
                        p2: ''
                    }
                }
            }
        },
        components: {
            'remark': Remark,
            'store_mod': store_mod,
            'logisticsCompany': logisticsCompany,
            'place': place,
            'tracer': tracer,
            'quickRoomSearch': quickRoomSearch,
            'name-find': name,
            'phone-find': phone,
            'shelf': shelf
        },
        methods:{
            setPid(value){
                this.newPackageData.packageId = value;
            },
            setDeliver(deliver){
                this.newPackageData.deliver = deliver;
            },
            setPlace(place){
                this.newPackageData.place = place;
            },
            setUser(userDetail){
                this.user.find = true;
                this.user.name = userDetail.name;
                this.user.phone = userDetail.phone;
                this.user.uuid = userDetail['stu_uuid'];
            },
            clearData(){
                this.newPackageData = {
                    packageId: '',
                    deliver: '',
                    place: '',
                    place_1:  '',
                    tracer: ''
                };
                this.multiArea = '';
                this.roomQuickFind = '';
                this.user = {
                    find: false,
                    name: '',
                    uuid: '',
                    phone: ''
                }
                this.privateRemark = '';
                this.publicRemark = '';
            },
            remarkSelect(who){
                this.middleName = 'remark';
                this.remarkTarget = who;
            },
            fillOutRemark(remark){
                if(this.middleName === 'remark'){
                    if(this.remarkTarget === "Public"){
                        this.publicRemark = remark;
                    }else{
                        this.privateRemark = remark;
                    }
                }
            },
            setShelf(shelf){
              this.newPackageData.place_1 = shelf
            },
            submit(){
                // 左方新的包裹提交
                let req;
                if(this.multiMode){
                    req = {
                        deliver: this.newPackageData.deliver,
                        place: this.newPackageData.place,
                        publicRemark: this.publicRemark,
                        privateRemark: this.privateRemark,
                        mode: 'multi',
                        tracer: this.multiAreaSplit
                    }
                }else{
                    req = {
                        deliver: this.newPackageData.deliver,
                        place: this.newPackageData.place,
                        publicRemark: this.publicRemark,
                        privateRemark: this.privateRemark,
                        mode: 'single',
                        place_1: this.newPackageData.place_1,
                        user: this.user.uuid,
                        tracer: this.newPackageData.tracer,
                        pid: this.newPackageData.packageId
                    }
                }
                axios.post('/api/package/', req)
                .then((resp)=>{
                    this.clearData();
                    window.alert(resp.data.msg);
                    this.syncRecord();
                }).catch((error)=>{
                    if(error.response && error.response.status === 400){
                        window.alert(error.response.data.msg);
                    }else {
                        window.console.log(error);
                        window.alert('出現了一些錯誤，請找黃彥儒抓蟲');
                    }
                    this.syncRecord();
                })
            },
            syncRecord(){
                axios.get('/api/package/')
                .then((resp)=>{
                    this.packageList.items = resp.data
                })
            },
            rightTableFilter(row){
                if(this.packageList.onlyShowUnpick && row.status !== '待領'){
                    return false
                }
                if(this.packageList.user.locked){
                    // 目前是有指定User的，所以若filterStr有東西，也要一併顯示
                    if(this.packageList.filter.length === 0){
                        return row['recipient_id'] === this.packageList.user.uuid || row['friend_id'] === this.packageList.user.uuid || row['recipient_id'] === null
                    }else {
                        return row['recipient_id'] === this.packageList.user.uuid || row['friend_id'] === this.packageList.user.uuid || row.tracer === this.packageList.filter
                    }
                }else{
                    // 並未指定User，所以若filterStr為空，顯示全部包裹
                    if(this.packageList.filter.length === 0){
                        return true
                    }else{
                        return row.tracer === this.packageList.filter
                    }
                }
            },
            queryPerson(){
                axios.get('/api/package/user?kw=' + this.packageList.personKeyWord)
                .then((resp)=>{
                    this.packageList.user = resp.data;
                    this.packageList.personKeyWord = this.packageList.user.name
                })
                this.syncRecord();
            },
            clearPerson(){
                this.syncRecord();
                this.packageList.user = {
                    locked: false,
                    name: '',
                    uuid: ''
                };
                this.packageList.personKeyWord = '';
            },
            pickPackage(packageId){
                // 領取包裹
                const formData = new FormData();
                let config = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
                formData.append('action', '領取');
                formData.append('taker_id', this.packageList.user.uuid);
                formData.append('package_id', packageId);
                axios.post('/api/package/action', formData, config).then((resp)=>{
                    this.packageList.infoFlash = resp.data.msg;
                    this.syncRecord();
                }).catch((error)=>{
                    if(error.response){
                        this.packageList.alertFlask = error.response.data.msg;
                    }else {
                        this.packageList.alertFlask = error.msg;
                    }
                    this.syncRecord();
                })
            },
            managePackage(packageId, action){
                if (!(action === '刪除' && !window.confirm('確定刪除包裹？'))){
                    const formData = new FormData();
                    let config = {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                    formData.append('action', action);
                    formData.append('package_id', packageId);
                    axios.post('/api/package/action', formData, config).then((resp)=>{
                        this.syncRecord();
                        this.packageList.infoFlash = resp.data.msg;
                    }).catch((error)=>{
                        this.syncRecord();
                        if(error.response){
                            this.packageList.alertFlask = error.response.data.msg;
                        }else {
                            this.packageList.alertFlask = error.msg;
                        }

                    })
                }
            },
            showMovePackage(packageId){
                this.$bvModal.show('move-package-modal')
                this.packageList.modalValue.modalPass = packageId;
            },
            resetModalValue(){
                this.packageList.modalValue = {
                    modalPass: '', // 傳送包裹編號到Modal裡面以完成移動位置的操作,
                    p1: '',
                    p2: ''
                };
            },
            movePackage(){
                const formData = new FormData();
                let config = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
                formData.append('action', '移動');
                formData.append('package_id', this.packageList.modalValue.modalPass);
                formData.append('p1', this.packageList.modalValue.p1);
                formData.append('p2', this.packageList.modalValue.p2);
                axios.post('/api/package/action', formData, config).then((resp)=>{
                    this.$bvModal.hide('move-package-modal');
                    this.packageList.infoFlash = resp.data.msg;
                    this.syncRecord();
                }).catch((error)=>{
                    if(error.response){
                        this.packageList.alertFlask = error.response.data.msg;
                    }else {
                        this.packageList.alertFlask = error.msg;
                    }
                    this.$bvModal.hide('move-package-modal');
                    this.syncRecord();
                })
            },
            notifyAgain(pid, kind){
                const formData = new FormData();
                let config = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
                formData.append('action', kind);
                formData.append('package_id', pid);
                axios.post('/api/package/action', formData, config).then((resp)=>{
                    this.packageList.infoFlash = resp.data.msg;
                    this.syncRecord();
                }).catch((error)=>{
                    if(error.response){
                        this.packageList.alertFlask = error.response.data.msg;
                    }else {
                        this.packageList.alertFlask = error.msg;
                    }
                    this.syncRecord();
                })
            },
            notifyAll(day){
                if(window.confirm("即將發送通知予包裹已經存放" + day + '天仍未領取的同學。')){
                    const formData = new FormData();
                    let config = {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                    formData.append('action', '通知全部');
                    formData.append('day', day);
                    axios.post('/api/package/action', formData, config).then((resp)=>{
                        this.packageList.infoFlash = resp.data.msg;
                        this.syncRecord();
                    }).catch((error)=>{
                        if(error.response){
                            this.packageList.alertFlask = error.response.data.msg;
                        }else {
                            this.packageList.alertFlask = error.msg;
                        }
                        this.syncRecord();
                    })
                }
            }
        },
        computed:{
            multiAreaRowLen: function () {
                return this.multiAreaSplit.length
            },
            multiAreaSplit: function () {
                let l = this.multiArea.split('\n');
                let o = [];
                for (let v of l){
                    if(v.length !== 0){
                        o.push(v);
                    }
                }
                return o
            },
            fillPackage: function () {
                return this.newPackageData.packageId !== '' && this.newPackageData.deliver !== '' &&this.newPackageData.place !== '' &&this.newPackageData.place_1 !== ''
            }
        },
        mounted: function () {
            this.$nextTick(function () {
                this.syncRecord();
            })
        }
    }
</script>

<style scoped>
    .btnLeft {
        float: left
    }
    .halfBtn {
        width: 50%;
    }
</style>
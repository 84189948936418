<template>
    <div>
        <b-card title="物流單號說明">
            <b-card-text>請掃描條碼，下面提供學生預先輸入的物流單號，運氣好的話可能不用再Key其他資料就可以建檔了。<br>用手打的話請按Enter鍵搜尋。</b-card-text>
            <b-card-text>
                <ul>
                    <li  v-bind:key="index" v-for="(item, index) in resp">
                        <b-button variant="link" @click="throwValue(item)">{{item.show}}</b-button>
                    </li>
                </ul>
            </b-card-text>
        </b-card>
    </div>
</template>

<script>
    import axios from "axios";
    export default {
        name: "tracer",
        props:{
            tracerId: String
        },
        data: function () {
            return {
                resp: []
            }
        },
        methods:{
            matchTracerId(){
                axios.get('/api/package/suggest', {
                    params: {
                        type: "per-reg",
                        keyword: this.tracerId
                    }
                }).then((response)=>{
                    this.resp = response.data;
                })
            },
            throwValue(item){
                this.$emit('userSelected', item);
            }
        }
    }
</script>

<style scoped>

</style>